import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Route, Routes } from "react-router-dom";
import "./App.css";
import DealsPage from "./components/DealsPage/DealsPage";
import Banner from "./components/Banner/Banner";
import Pagination from "./components/Pagination/Pagination";
import Impressum from "./components/Impressum/Impressum";
import Datenschutz from "./components/Datenschutz/Datenschutz";

function App() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div className="fixed" style={{ position: "relative" }}>
        <div
          style={{
            bottom: "0%",
            left: "50%",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            color: "red", // You can customize the color
            zIndex: 3, // Ensures the text is above the banner
          }}
        >
          NICE TRY DU HUND
        </div>
      </div>
    </div>
  );
}

export default App;
